import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';


const Select = props => {
 	const {name, options, ...rest} = props;
 	return(
 		<div className="form-group">
			<ErrorMessage name={name} component={TextError}/>
			<Field as="select" id={name} name={name} className="form-input" {...rest}>
				{
					options.map( option => {
						return(
							<option key={option.key} value={option.value}>
								{option.key}
							</option>
						);
					})
				}
			</Field>
		</div>
 	);
};

export default Select;