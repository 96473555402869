import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../form/FormikControl';


const StepOne = (props) => {

	const validationSchema = Yup.object().shape({
		
	});
	
	const handleSubmit = (values) => {
        props.handleNext(values);
	};

	return(
		<Formik 	
			initialValues={props.data}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{ ({values}) => {
				return (
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								<span>Demande de devis</span>
							</div>
							<h2>Choisissez le type de votre projet : </h2>
							<div className="form-content">
								<div className='form-groups'>
									<FormikControl control="radio" name="project_type" value="vitrine" label="vitrine"/>
									<FormikControl control="radio" name="project_type" value="application" label="application"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="radio" name="project_type" value="média" label="media"/>
									<FormikControl control="radio" name="project_type" value="e-commerce" label="e-commerce"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="radio" name="project_type" value="autre" label="autre"/>
								</div>
								{values.project_type !== "vitrine" 
								&& values.project_type !== "application"
								&& values.project_type !== "média" 
								&& values.project_type !== "e-commerce"?
								<div className='form-groups'>
									<FormikControl control="input" name="project_type"  label="quel type de site ?"/>
								</div>
								:null}
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => props.setMdlCreate(false)} className="form-btn form-btn-thirdary">
									annuler
								</button>
								<button className="form-btn form-btn-secondary" type="submit">
									Suivant								
								</button>
							</div>
						</Form>
					</div>
				)}
			}
		</Formik>
	);
};
export default StepOne;