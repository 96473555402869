import { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import './Erp.css';
import Loader from '../../loader/Loader';
const  VideoPalyer = lazy(() => import('../../videoPlayer/VideoPlayer'));

const ErpPlayVideo = () => {
    const { video_id } = useParams();
    return(
        <div className='erp-video-player'>
        <Suspense fallback={<Loader/>}>
        <VideoPalyer url={`${process.env.REACT_APP_SERVER_URL}/output_dash/${video_id}/output.mpd`}/>
        </Suspense>
        </div>
    );
};

export default ErpPlayVideo;