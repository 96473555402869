import React, { useReducer } from 'react';
export const GlobalContext = React.createContext();
export const UpdateContext = React.createContext();

const reducer = (state, action) => {
	switch(action.type){
		case 'SIDE_BAR' : 
			return {
				side_drawer : !action.payload.side_drawer
			}
		default : 
			return state
	}
};

export const GlobalProvider = ({children}) => {
	
	const initialStore = { side_drawer : false} ;
	
	const [store, dispatch] = useReducer(reducer, initialStore);
	
	return(
		<GlobalContext.Provider value={store}>
			<UpdateContext.Provider value={dispatch}>
				{children}
			</UpdateContext.Provider>
		</GlobalContext.Provider>
	);
};	


