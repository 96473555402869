import './Hosting.css';
import Modal from '../../modal/Modal';
import HostingCreate from './HostingCreate';
import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const Hosting = () => {
    const [ MdlCreate, setMdlCreate ] = useState(false);
    const [ pack, setPack ] = useState("");
   
    return(
        <div className='hosting'>
            <HelmetProvider>
                <Helmet>
                    <title>TKDevelop | Hébergement web et VPS en Algérie </title>
                    <link rel="canonical" href="https://tkdevelop-dz.com/hosting" />
                    <meta name="description" content="TKDevelop | Hébergement Web et VPS - Tizi Ouzou, Algérie 
                    | Hébergement de sites et applications web : Site Vitrien - Application  web - e-commerce
                    | VPS (serveurs virtuel privé) : VPS bon marché  et moins chère Algérie - Administration systémes - CI/CD (Intégration et déploiement continue)
                    "/>
                </Helmet>
            </HelmetProvider>
            <div className='page'>
                <p><Link to="/">acceuil</Link></p>
                <p>{">"}</p>
                <p>Hébergement</p>
            </div>
            <h1>Serveurs virtuels privés (VPS)</h1>
            <div className='vps-cards'>
                <div className='vps-card'>
                    <div className='vps-card-title'> 
                        <span>Pack 00</span>
                        <span>2 500.00 DA/ Mois</span>
                    </div>
                    <ul className='vps-card-content'>
                        <li>1 Processeurs</li>
                        <li>2 Go RAM</li>
                        <li>40 Go Stockage SSD</li>
                        <li>250 Mbit/S - Illimité</li>
                        <li>IPV4/ IPV6</li>
                        <li>Configuration</li>
                        <li>Administration</li>
                        <li>Développement CI/CD</li>
                    </ul>
                    <div className='vps-card-action'>
                        <button onClick={() => { setMdlCreate(true); setPack({
                                title : "Pack 00 - 2 500.00 DA/ Mois",
                                configs : "1 Processeurs - 2 Go RAM - 40 Go Stockage SSD - 250 Mbit/S - Illimité - IPV4/ IPV6",
                                options : ""
                                }
                            )}
                            }>
                            <span>Commander</span>
                            <svg className="vps-card-icon"> 
                                <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='vps-card'>
                    <div className='vps-card-title'> 
                        <span>Pack 01</span>
                        <span>6 700.00 DA/ Mois</span>
                    </div>
                    <ul className='vps-card-content'>
                        <li>2 Processeurs</li>
                        <li>4 Go RAM</li>
                        <li>80 Go Stockage SSD</li>
                        <li>500 Mbit/S - Illimité</li>
                        <li>IPV4/ IPV6</li>
                        <li>Configuration</li>
                        <li>Administration</li>
                        <li>Développement CI/CD</li>
                    </ul>
                    <div className='vps-card-action'>
                        <button onClick={() => { setMdlCreate(true); setPack({
                                title : "Pack 01 - 6 700.00 DA/ Mois",
                                configs : "2 Processeurs - 4 Go RAM - 80 Go Stockage SSD - 500 Mbit/S - Illimité - IPV4/ IPV6",
                                options : ""
                                })}}>
                            <span>Commander</span>
                            <svg className="vps-card-icon"> 
                                <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='vps-card'>
                    <div className='vps-card-title'> 
                        <span>Pack 02</span>
                        <span>8 500.00 DA/ Mois</span>
                    </div>
                    <ul className='vps-card-content'>
                        <li>4 Processeurs</li>
                        <li>8 Go RAM</li>
                        <li>160 Go Stockage SSD</li>
                        <li>1 Gbit/S - Illimité</li>
                        <li>IPV4/ IPV6</li>
                        <li>Configuration</li>
                        <li>Administration</li>
                        <li>Développement CI/CD</li>
                    </ul>
                    <div className='vps-card-action'>
                        <button onClick={() => { setMdlCreate(true); setPack({
                                title : "Pack 02 - 8 500.00 DA/ Mois",
                                configs : "4 Processeurs - 8 Go RAM - 160 Go Stockage SSD - 1 Gbit/S - Illimité - IPV4/ IPV6",
                                options : ""
                                })}}>
                            <span>Commander</span>
                            <svg className="vps-card-icon"> 
                                <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='vps-card'>
                    <div className='vps-card-title'> 
                        <span>Pack 03</span>
                        <span>11 500.00 DA/ Mois</span>
                    </div>
                    <ul className='vps-card-content'>
                        <li>8 Processeurs</li>
                        <li>8 à 32 Go RAM</li>
                        <li>160 à 640 Go Stockage SSD</li>
                        <li>2 Gbit/S - Illimité</li>
                        <li>IPV4/ IPV6</li>
                        <li>Configuration</li>
                        <li>Administration</li>
                        <li>Développement CI/CD</li>
                    </ul>
                    <div className='vps-card-action'>
                        <button onClick={() => { setMdlCreate(true); setPack({
                                title : "Pack 03 - 11 500.00 DA/ Mois",
                                configs : "8 Processeurs - 8 à 32 Go RAM - 160 à 640 Go Stockage SSD - 2 Gbit/S - Illimité - IPV4/ IPV6",
                                options : ""
                                })}}>
                            <span>Commander</span>
                            <svg className="vps-card-icon"> 
                                <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <Modal open={MdlCreate}>
                <div className='modal-close'>
                    <button onClick={() => setMdlCreate(false)}>
                        <svg className="modal-close-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-circle-with-cross"></use>
                        </svg> 
                    </button>
                </div>
                <div className='modal-content'>
                    <HostingCreate setMdlCreate={setMdlCreate} pack={pack}/>
               </div>
            </Modal>
        </div>
    );
};

export default Hosting;