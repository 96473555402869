import { useContext } from 'react';
import { GlobalContext, UpdateContext } from '../../context/Global';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const store = useContext(GlobalContext);
    const dispatch = useContext(UpdateContext);
    const handleSidebar = () => {
        dispatch({ type : "SIDE_BAR", payload : { side_drawer : store.side_drawer}});
    }
    return(
        <div className={`drawer ${ store.side_drawer ? "open" : null}`} 
        style={{
            backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-1-mobile.webp"})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
            }}
        >
            <div>
                <div className='drawer-logo'>
                    <img src='/img/logo/alpha/logo_alpha_md.webp' alt="tkdevelop"/>
                </div>
                <ul>
                    <li onClick={handleSidebar}><NavLink to="/">accueil</NavLink></li>
                </ul>
                <p>Services : </p>
                <ul style={{ paddingLeft : '1.2rem'}}>
                    <li onClick={handleSidebar}><NavLink to="/developing">web</NavLink></li>
                    <li onClick={handleSidebar}><NavLink to="/hosting">Hébergement</NavLink></li>
                    <li onClick={handleSidebar}><NavLink to="/matyn-erp">MAtyn ERP</NavLink></li>
                    <li onClick={handleSidebar}><NavLink to="/marketing">Marketing</NavLink></li>
                </ul>
                <ul>
                    <li onClick={handleSidebar}><NavLink to="/about">a propos</NavLink></li>
                </ul>
            </div>
            <div></div>
        </div>
    );
};
export default Sidebar; 