import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";

import './Marketing.css';

const Marketing = () => {
    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>TKDevelop | Marketing Digital Algérie </title>
                    <link rel="canonical" href="https://tkdevelop-dz.com/marketing" />
                    <meta name="description" content="TKDevelop | Marketing Digital - Tizi Ouzou, Algérie
                    | Référencement SEO
                    | Emainling : Compagnes et base de données de prospect
                    | Réseaux sociaux : Page facebook - Chaine youtube
                    | Infographie : Charte graphique
                    | Vidéo d'animation : Vidéo motion design
                    "/>
                </Helmet>
            </HelmetProvider>
            <div className='page'>
                <p><Link to="/">acceuil</Link></p>
                <p>{">"}</p>
                <p>marketing digital</p>
            </div>
            <div className="marketing">
                <h1>
                    Marketing digital
                </h1>
                <h2>
                    C’est quoi le marketing digital ?
                </h2>
                <p>C’est vendre des produits, des services ou même une idée, en ligne (internet) en surpassant les contraintes de la 
                    rencontre physique avec les prospects ou avec les clients.<br/>
                    L’objectif de marketing digital est d’acquérir de nouveau client ou communiquer avec les clients actuels en utilisant les canaux 
                    digitaux ou numériques.
                </p>
                <h2>comment se fait le marketing digital ?</h2> 
                <p>    
                    Avec l’évolution rapide des nouvelles technologies de l’information, de nouveaux métiers voient le jour dans le 
                    domaine de marketing digital, comme par exemple :
                </p>
                <ul> 
                    <li> - la création des sites et contenus web.</li>
                    <li> - La gestion des communautés sur les réseaux sociaux.</li>
                    <li> - compagne d’Emailing.</li>
                    <li> - Publicité payante sur le moteur de recherche Google ou achat de mots clefs.</li>
                    <li> - Marketing automation.</li>
                    <li> - Marketing de contenu.</li>
                    <li> - SEO : Search Engine Optimization ou référencement naturel.</li>
                    <li> - SMO : Social Media Optimization ou référencement naturel sur les réseaux sociaux.</li>
                </ul>
                <p>
                    Ces techniques sont utilisées dans le métier de marketing digital assistées :<br/> 
                    D’un côté par les appareils de communications modernes telles que les smartphones, pc, tablette et laptop.<br/>
                    De l’autre côté, des infrastructures numériques géantes sont mises à la disposition du métier de marketing digital telle que :  
                    Google et ses api puissantes qui exposent des services indispensables pour les entreprises, Facebook et ses applications qui 
                    tournent dans son orbite.
                </p>
                <p>
                    <strong style={{ textTransform: "uppercase", color : "var(--color-primary)"}}>TKDevelop</strong> peut vous être utile dans le domaine de marketing digital, vous pouvez nous contacter pour évaluer vos besoins.
                </p> 
                <p> Nous pouvons :</p>
                <ul>
                    <li> - Créer votre site web en prenant en considération à l’avance, le référencement naturel SEO.</li>
                    <li> - Former vos équipes commerciales aux techniques de réalisation de compagne d’emailing.</li>
                    <li> - Mettre à votre disposition une base de données de plus de 10 000 entreprises.</li>
                    <li> - Production des contenus web.</li>
                </ul>
            </div>
        </>
    );
};

export default Marketing;