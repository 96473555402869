import { useContext, useState } from 'react';
import { GlobalContext, UpdateContext } from '../../context/Global';
import { NavLink } from 'react-router-dom';
import './Nav.css'

const Nav = () => {
    const dispatch = useContext(UpdateContext);
    const store = useContext(GlobalContext);

    const [ dropdown, setDropdown ] = useState(false);

    const handleSidebar = () => {
        dispatch({type : "SIDE_BAR", payload : { side_drawer : store.side_drawer} })
    }
    return(
        <nav className='nav'>
            <div className='nav-menu'>
                <svg className="nav-menu-icon" onClick={handleSidebar}> 
                    <use xlinkHref="/img/icons/sprite.svg#icon-menu"></use>
                </svg>
            </div>
            <div className='nav-logo'>
                <img src='/img/logo/beta/logo_beta_xs.png' alt="tkdevelop"/>
            </div>
            <ul>
                <li><NavLink to="/">accueil</NavLink></li>
                <li className='dropdown'>
                    <button onClick={() => setDropdown((prev) => !prev)}>
                        <span>services</span>
                        <span><svg className="nav-dropdown-icon"> <use xlinkHref="/img/icons/sprite.svg#icon-chevron-down"></use></svg></span>
                    </button>
                    <p className={dropdown ? 'open' : ''}>
                        <span onClick={() => setDropdown(false)}><NavLink to="/developing">Web</NavLink></span>
                        <span onClick={() => setDropdown(false)}><NavLink to="/hosting">Hébergement</NavLink></span>
                        <span onClick={() => setDropdown(false)}><NavLink to="/matyn-erp">MAtyn ERP</NavLink></span>
                        <span onClick={() => setDropdown(false)}><NavLink to="/marketing">Marketing</NavLink></span>
                    </p>
                </li>
                <li><NavLink to="/about">a propos</NavLink></li>
            </ul>
        </nav>
    )
};
export default Nav;