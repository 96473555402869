import './About.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const About = () => {
    return(
        <>
        <HelmetProvider>
        <Helmet>
            <title>TKDevelop | Agence d'infogérance Tizi Ouzou Algérie </title>
            <link rel="canonical" href="https://tkdevelop-dz.com/about" />
            <meta name="description" content="TKDevelop : Agence d'infogérance, Fondateur : TAKILT Asmane - Adresse : Tizi Ouzou, Algérie - Tél : +213 781 803 456"/>
        </Helmet>
        </HelmetProvider>
        <div className='page'>
            <p><Link to="/">acceuil</Link></p>
            <p>{">"}</p>
            <p>A propos</p>
        </div>
        <div className='about'>
            
           
            <h1>A propos de TKDevelop ?</h1>
            <p>TKDevelop est une agence d'infogérance basée à Tizi Ouzou, fondée par Mr. TAKILT Asmane, 
            immatriculée à l'agence nationale des auto-entrepreneurs sous le N° 108402210037324879 et aux impôts sous le
            NIF 18415360373015601580.<br/> Son activité principale est le maintien et développement de l'ERP Matyn, elle  propose aussi 
            d'autres services tel que le développement des sites et application web modèle MERN et le marketing digital.
            </p>
            <ul>
                <li>
                    <span>
                        <svg className="about-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-user"></use>
                        </svg>
                    </span>
                    <span>Fondée par TAKILT Asmane, Entrepreneur web</span></li>
                <li>
                    <span>
                        <svg className="about-icon"> 
                        <use xlinkHref="/img/icons/sprite.svg#icon-alternate_email"></use>
                    </svg>
                    </span>
                    <span>Email : tkdevelop.dz@gmail.com</span></li>
                <li>
                    <span>
                        <svg className="about-icon"> 
                        <use xlinkHref="/img/icons/sprite.svg#icon-phonelink_ring"></use>
                    </svg>
                    </span>
                    <span>Tél : +213 781 803 456</span></li>
                <li>
                    <span>
                        <svg className="about-icon"> 
                        <use xlinkHref="/img/icons/sprite.svg#icon-pin_drop"></use>
                    </svg>
                    </span>
                    <span>Adresse : Ait Bouaddou, Wilaya de Tizi Ouzou</span></li>
            </ul>
        </div>
        </>
    );
};

export default About;