import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

const Input = props => {
 	const {label, name, ...rest} = props;
 	return(
 		<div className="form-group">
			<ErrorMessage name={name} component={TextError}/>
 			<Field id={name} name={name} { ...rest} className="form-input" placeholder={label}/>
 			<label htmlFor={name} className="form-label">{label}</label>
 		</div>
 	);
};

export default Input;