import { useState, lazy, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Modal from '../../modal/Modal';
import { Link } from 'react-router-dom';
import './Erp.css';
import Loader from '../../loader/Loader';
const  VideoPalyer = lazy(() => import('../../videoPlayer/VideoPlayer'));


const Erp = () => {
    const [open, setOpen] = useState(false);
    const [videoNumber, setVideoNumber] = useState("");
    const [videoTitle, setVideoTitle] = useState("");

    return(
        <div className="erp">
        <HelmetProvider>
             <Helmet>
                <title>TKDevelop | ERP Matyn Algérie </title>
                <link rel="canonical" href="https://tkdevelop-dz.com/matyn-erp" />
                <meta name="description" content="TKDevelop | ERP Matyn - Tizi Ouzou, Algérie 
                | Matyn ERP : Achats - Stocks - Finances - Logistique - CRM - Ventes - Ressources Humaines - Comptabilité
                | Tester Matyn ERP gratuitement
                "/>
            </Helmet>
        </HelmetProvider>
            <div className='page'>
                <p><Link to="/">acceuil</Link></p>
                <p>{">"}</p>
                <p>Erp Matyn</p>
            </div>
            <div className="erp-intro">
                <img src="/img/matyn-erp/matyn-erp.png" alt="matyn-erp"/>
                <h2>L'ERP QUI RÉPOND MIEUX À VOS EXIGENCES</h2>
                <p>L'ERP Matyn est un progiciel de gestion intégré, autrement dit un ensemble d'applications métiers qui vous permet de piloter 
                l'ensemble des services de votre entreprise (Achats - Stocks - Finances et trésorerie - Logistique - Production - CRM - Ventes - Ressources Humaines - Comptabilité).<br/>
                Pour voir Matyn ERP en action, veuillez suivre notre formation. Une série de vidéos est consacrée à la présentation de tous les 
                modules qui composent Matyn ERP et à vous apprendre à maitriser l'ensemble de ses fonctionnalités.<br/>
                Vous pouvez suivre cette formation sur notre chaine youtube 
                <a href="https://www.youtube.com/playlist?list=PLhXsr4-W4zAE80nznpHwKHt-917xTTfyM" target="_blanc"> #Playlist MATYN ERP.</a><br/>
                Pour voir le contenu des modules de Matyn Erp, visiter le site dédié <a href="https://matyn-home.web.app" target="_blanc">#ici</a>

                </p>
            </div>
            <div className="erp-video">
                <h2>Formation vidéo matyn erp</h2>
                <ul>
                    <li onClick={() => { setOpen(true); setVideoNumber('001'); setVideoTitle("001 Matyn Erp Introduction")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>
                        <span>001 Matyn Erp Introduction</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('002'); setVideoTitle("002 Matyn Erp Test Gratuit")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>002 Matyn Erp Test Gratuit</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('003'); setVideoTitle("003 Matyn Erp Interface")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>003 Matyn Erp Interface</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('004'); setVideoTitle("004 Matyn Erp Créer un article")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>004 Matyn Erp Créer un article</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('005'); setVideoTitle("005 Matyn Erp Créer une demande d'achat")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>005 Matyn Erp Créer une demande d'achat</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('006'); setVideoTitle("006 Matyn Erp Créer une commande fournisseur")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>006 Matyn Erp Créer une commande fournisseur</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('007'); setVideoTitle("007 Matyn Erp Créer une livraison fournisseur")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>007 Matyn Erp Créer une livraison fournisseur</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('008'); setVideoTitle("008 Matyn Erp Créer une facture d'achat")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>008 Matyn Erp Créer une facture d'achat</span>
                    </li>
                    <li onClick={() => { setOpen(true); setVideoNumber('009'); setVideoTitle("009 Matyn Erp Créer une note de frais")}}>
                        <svg className="erp-video-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-video"></use>
                        </svg>                        
                        <span>009 Matyn Erp Créer une note de frais</span>
                    </li>
                </ul>
            </div>
            <div className='erp-applications'>
                <h1>Matyn ERP contient les applications suivantes : </h1>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/company.png' alt='matyn-erp'/>
                        <h2>ENTREPRISE</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Créer et gérer le dossier administratif de l'entité et ses annexes.</li>
                        <li>- Echanger vos documents (RC, NIF ...) en toutes sécurité avec vos collaborateurs.</li>
                        <li>- Gérer vous mêmes vos utilisateurs et leurs autorisations sur le système.</li>
                        <li>- Créer vos auxiliaires (Clients, Fournisseurs...).</li>
                        <li>- Créer les tableaux de structure des données.</li>
                        <li>- Gérer la traduction de votre système.</li>
                        <li>- Accéder à votre tableau de bord.</li>
                        <li>- Gérer la page d'accueil de système.</li>
                    </ul>
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/crm.png' alt='matyn-erp'/>
                        <h2>crm</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Base locales de prospects.</li>
                        <li>- Gérer les entretiens et intéractions.</li>
                        <li>- Etablissement des devis.</li>
                        <li>- Classer les prospects avec des états personalisés.</li>
                        <li>- Mesurer l'activité des commerciaux.</li>
                        <li>- Centrer les données de la prospection dans une seule Base de données.</li>
                    </ul> 
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/sales.png' alt='matyn-erp'/>
                        <h2>ventes</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer les points de ventes.</li>
                        <li>- Gérer les demandes devis clients.</li>
                        <li>- Gérer les commandes clients.</li>
                        <li>- Gérer les livraisons.</li>
                        <li>- Gérer la facturation.</li>
                        <li>- Suivi des clients et leurs paiements.</li>
                        <li>- Suivre le chiffre d'affaire par activité et par commercial.</li>
                        <li>- Impression en pdf des devis, bons de livraison et factures.</li>
                    </ul> 
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/purchase.png' alt='matyn-erp'/>
                        <h2>achats</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer les demandes d'achats.</li>
                        <li>- Gérer les bons de commandes fournisseurs.</li>
                        <li>- Gérer les bons de livraisons.</li>
                        <li>- Gérer les factures d'achats.</li>
                        <li>- Gérer les notes de frais.</li>
                        <li>- Générer les demandes de paiement.</li>
                        <li>- Suivre le paiement des factures et notes de frais.</li>
                        <li>- Imprimer en PDF les demandes d'achats et bon de commandes.</li>
                    </ul> 
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/stock.png' alt='matyn-erp'/>
                        <h2>stock</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer les réceptions.</li>
                        <li>- Gérer les livraisons.</li>
                        <li>- Gérer les transferts.</li>
                        <li>- Gérer les inventairs</li>
                        <li>- Gérer plusieurs dépôts.</li>
                        <li>- Gérer les accés aux dépôts.</li>
                        <li>- Générer les sotcks en temps réel.</li>
                        <li>- Imprimer en PDF les documents relatifs aux stocks.</li>
                    </ul> 
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/treasury.png' alt='matyn-erp'/>
                        <h2>finances</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer les demandes de paiements.</li>
                        <li>- Gérer vos comptes bancaires séparément.</li>
                        <li>- Gérer le brouillard des opérations journalières de chaque compte.</li>
                        <li>- Gérer vos caisses séparément (principale et annexes).</li>
                        <li>- Obtenir le solde journalier rapproché de tous les comptes bancaires.</li>
                        <li>- Exporter le brouillard de banque et caisse sous format excel.</li>
                        <li>- Imprimer en pdf les avis de versement espèces.</li>
                        <li>- Imprimer en pdf les virements et les demandes de chèque.</li>
                    </ul> 
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/hr.png' alt='matyn-erp'/>
                        <h2>ressources humaines</h2>
                    </div>
                    <ul className='erp-application-modules'> 
                        <li>- Gérer les demandes de recrutements.</li>
                        <li>- Gérer les offres d'emplois.</li>
                        <li>- Collaborer avec l'agences d'emplois.</li>
                        <li>- Gérer les candidatures.</li>
                        <li>- Gérer le personnel.</li>
                        <li>- Gérer les contrats.</li>
                        <li>- Gérer les congés.</li>
                        <li>- Gérer les formations.</li>
                        <li>- Imprimer en pdf tous les documents relatifs au personnel (contrats, certificat, ats...).</li>
                    </ul>
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/payroll.png' alt='matyn-erp'/>
                        <h2>paie</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer la préparation de la paie.</li>
                        <li>- Elaborer la paie en un seule clic avec l'importation du pointage.</li>
                        <li>- Calculer tous les paramétres relatifs à la paie (charge fiscale et parafiscale).</li>
                        <li>- Suivre dans le temps la masse salariale sur le tableau de bord.</li>
                        <li>- Imprimer ou exporter le virement multiple de la paie.</li>
                        <li>- Imprimer les fiches de paie.</li>
                        <li>- Gérer la grille des salaires.</li>
                        <li>- Structurer les éléments de la paie (les primes, indemnités, retenues...).</li>
                    </ul>
                </div>
                <div className='erp-application'>
                    <div className='erp-application-title'>
                        <img src='/img/matyn-erp/modules/logistic.png' alt='matyn-erp'/>
                        <h2>logistique</h2>
                    </div>
                    <ul className='erp-application-modules'>
                        <li>- Gérer les dépôts multiples de l'entité</li>
                        <li>- Gérer les flux de réceptions et livraisons (achats et ventes).</li>
                        <li>- Gérer les flux internes (livraison entre dépôts, vers les projets...)</li>
                        <li>- Gérer le parc roulant (liste materiéls, les assurances, les docs scannées...).</li>
                        <li>- Gérer la consommation de carburant et lubrifiants.</li>
                        <li>- Gérer les réparations et pièces de rechanges.</li>
                        <li>- Gérer et imprimer les ordres de missions.</li>
                        <li>- Gérer les locations.</li>
                    </ul> 
                </div>
            </div>
            <Modal open={open}>
                <div className='modal-close'>
                    <button onClick={() => setOpen(false)}>
                        <svg className="modal-close-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-circle-with-cross"></use>
                        </svg> 
                    </button>
                </div>
                <div className='modal-content'>
                    <Suspense fallback={<Loader/>}>
                    <VideoPalyer url={`${process.env.REACT_APP_SERVER_URL}/output_dash/${videoNumber}/output.mpd`}/>
                    <p>{videoTitle}</p>
                    </Suspense>
                </div>
            </Modal>
        </div>
    );
};

export default Erp;