import { Field} from 'formik';

const Radio = props => {
 	const { lang, label, name, ...rest} = props;
 	return(
 		<div className="form-group">
		<label className="radio_input_container">
			{label}
			<Field type="radio" name={name} {...rest}/>
			<span className="radio_input_checkmark"></span>
		</label>	
		</div>
 	);
};

export default Radio;

							