import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../form/FormikControl';

const StepThree = (props) => {
    
    const validationSchema = Yup.object().shape({
		project_language : Yup.string().required('Entrer une valeur!').max(25,'25 caracters max!'),
	});

    const handleSubmit = (values) => {
       props.handleNext(values)
    };
    
    return(
        <Formik 	
			initialValues={props.data}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{ ({values}) => {
				return (
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								<span>Demande de devis</span>
							</div>
							<h2>La langue de votre projet  : </h2>
							<div className="form-content">
								<div className='form-groups'>
									<FormikControl control="input" name="project_language"  label="La langue souhaitée"/>
								</div>
								<h2>Plusieurs langues : </h2>
								<div className='form-groups'>
									<FormikControl control="radio" name="project_language_multiple" value="non" label="Non"/>
									<FormikControl control="radio" name="project_language_multiple" value="oui" label="Oui"/>
								</div>
								{values.project_language_multiple !== "non" ?
								<div className='form-groups'>
									<FormikControl control="input" name="project_language_multiple"  label="Ajouter les langues souhaitées ?"/>
								</div>
								:null}
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => props.setMdlCreate(false)} className="form-btn form-btn-thirdary">
									annuler
								</button>
								<button className="form-btn form-btn-secondary" type="submit">
									Suivant								
								</button>
								<button className="form-btn form-btn-secondary" type="button" onClick={() => props.handlePrev(values)}>
                                    précédent
                                </button>
							</div>
						</Form>
					</div>
				)}
			}
		</Formik>
    );
};

export default StepThree;