import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../form/FormikControl';
import { Link } from 'react-router-dom';

const StepFour = (props) => {
    
    const validationSchema = Yup.object().shape({
		
	});

    const handleSubmit = (values) => {
       props.handleNext(values)
    };
    
    return(
        <Formik 	
			initialValues={props.data}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{ ({values}) => {
				return (
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								<span>Demande de devis</span>
							</div>
							<h2>Hébergement de votre projet  : </h2>
							<div className="form-content">
                                <div className='form-groups'>
									<FormikControl control="radio" name="project_hosting" value="hébergement mutualisé" label="hébergement mutualisé"/>
									<FormikControl control="radio" name="project_hosting" value="je m'en charge moi-même"  label="je m'en charge moi-même"/>
								</div>
                                <div className='form-groups'>
									<FormikControl control="radio" name="project_hosting" value="hébergement premium" label="hébergement haute configuaration"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="radio" name="project_hosting" value="hébergement tkdevelop"  label="vps formule tkdevelop"/>
								</div>
                                <h2>les offres VPS de TKDevelop <Link to="/hosting" target="_blank">ici</Link></h2>
								{values.project_hosting !== "hébergement mutualisé"
                                && values.project_hosting !== "je m'en charge moi-même" 
                                && values.project_hosting !== "hébergement premium" ?
								<div className='form-groups'>
									<FormikControl control="select" name="project_hosting_vps"
                                        options={[
                                            {key : "choisissez un pack vps", value :""},
											{key : "PACK 00 : 2 500.00 DA/ Mois", value :"PACK 00 : 2 500.00 DA/ Mois"},
											{key : "PACK 01 : 6 700.00 DA/ Mois", value :"PACK 01 : 6 700.00 DA/ Mois"},
											{key : "PACK 02 : 8 500.00 DA/ Mois", value :"PACK 02 : 8 500.00 DA/ Mois"},
											{key : "PACK 03 : 11 500.00 DA/ Mois", value :"PACK 03 : 11 500.00 DA/ Mois"}
                                        ]}
                                    />
								</div>
								:null}
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => props.setMdlCreate(false)} className="form-btn form-btn-thirdary">
									annuler
								</button>
								<button className="form-btn form-btn-secondary" type="submit">
									suivant							
								</button>
								<button className="form-btn form-btn-secondary" type="button" onClick={() => props.handlePrev(values)}>
                                    précédent
                                </button>
							</div>
						</Form>
					</div>
				)}
			}
		</Formik>
    );
};

export default StepFour;