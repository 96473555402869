import ReactDom from 'react-dom';
import './Modal.css';

const Modal = ({children, open}) => {
	if(!open) return null
	return ReactDom.createPortal( 
		<span>
			<div className="overlay"></div>
			<div className={"modal "}>
				{children}	
			</div>
		</span>,
		document.getElementById('modal-root')
	);
};

export default Modal;