import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../form/FormikControl';


const StepFive = (props) => {
    
    const validationSchema = Yup.object().shape({
		name : Yup.string().required('Entrer une valeur!').max(100,'100 caracters max!'),
		email : Yup.string().required('Entrer une valeur!').email('entrer un email valide').max(50,'50 caracters max!'),
		phone : Yup.string().required('Entrer une valeur!').max(25,'25 caracters max!'),
		adress : Yup.string().required('Entrer une valeur!').max(100,'100 caracters max!'),
		category : Yup.string().required('Entrer une valeur!'),
		description : Yup.string().required('Entrer une valeur!').max(250,'250 caracters max!'),
	});

    const handleSubmit = (values) => {
       props.handleNext(values, true)
    };
    
    return(
        <Formik 	
			initialValues={props.data}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{ ({values}) => {
				return (
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								<span>Demande de devis</span>
							</div>
							<h2>Vos coordonnées de contact : </h2>
							<div className="form-content">
                                <div className='form-groups'>
									<FormikControl control="input" name="name"  label="nom / raison sociale"/>
								</div>
                                <div className='form-groups'>
									<FormikControl control="input" name="adress" label="adresse complète"/>
								</div>
                                <div className='form-groups'>
									<FormikControl control="input" name="email"  label="adresse email"/>
									<FormikControl control="input" name="phone" label="téléphone"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="select" name="category"
                                        options={[
                                            {key : "Choisissez votre catégorie", value :""},
											{key : "Entreprise", value :"Entreprise"},
											{key : "Entreprise en création", value :"Entreprise en création"},
											{key : "Association", value :"Association"},
											{key : "Etablissement public", value :"Etablissement public"}
                                        ]}
                                    />
								</div>
                                <div className='spacer'></div>
                                <div className='form-groups'>
									<FormikControl control="textarea" name="description" label="Petite déscription de votre projet:"/>
								</div>
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => props.setMdlCreate(false)} className="form-btn form-btn-thirdary">
									Annuler
								</button>
								<button className="form-btn form-btn-secondary" type="submit"
									disabled={props.reqStatus === 200 ? true: false }  
									style={props.reqStatus === 200 ? {cursor : "not-allowed"} : null}
								>
									{ props.loader ? <>Envoi...</> : <>Enregistrer</>}							
								</button>
								<button className="form-btn form-btn-secondary" type="button" onClick={() => props.handlePrev(values)}>
                                    Précédent
                                </button>
							</div>
						</Form>
					</div>
				)}
			}
		</Formik>
    );
};

export default StepFive;