import { useContext } from 'react';
import { GlobalContext, UpdateContext } from '../../context/Global';
import './Backdrop.css';

const Backdrop = () => {
    const store = useContext(GlobalContext);
    const dispatch = useContext(UpdateContext);

    const handleSidebar = () => {
        dispatch({type : "SIDE_BAR", payload : { side_drawer : store.side_drawer }})
    }
    return(
        <div className={`backdrop ${store.side_drawer ? "open" : "" }`} onClick={handleSidebar}></div>
    );
};

export default Backdrop;