import { useState } from 'react';
import axios from 'axios';
import '../../form/form.css';
import StepOne from './developingCreateSteps/StepOne';
import StepTwo from './developingCreateSteps/StepTwo';
import StepThree from './developingCreateSteps/StepThree';
import StepFour from './developingCreateSteps/StepFour';
import StepFive from './developingCreateSteps/StepFive';
import StepOneVitrine from './developingCreateSteps/StepOneVitrine';
import useRecaptchV3 from '../../hooks/recaptcha';

const DevelopingCreate = ({setMdlCreate}) => {

    const execRecaptcha = useRecaptchV3(`${process.env.REACT_APP_RECAPTCHA_KEY}`);

	const [ data, setData ] = useState({
        name : "",
        adress : "",
        email : "",
        phone : "",
        category : "",
        description : "",
        project_type : "vitrine",
        project_design : "standard",
        project_language : "Français",
        project_language_multiple : "non",
        project_hosting : "hébergement mutualisé",
        project_hosting_vps : "",
        site_vitrine : {
            page_acceuil : false,
            page_apropos : false,
            page_contact : false,
            page_produit : false,
            page_temoignage : false,
            page_carriere : false,
            form_contact : false,
            form_devis : false,
            form_rdv : false,
            blog : false,
            admin : false
        },
        site_advanced : ""
    });
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ loader, setLoader ] = useState(false);
    const [ reqStatus, setReqStatus ] = useState(0);
    const [ message, setMessage ] = useState("");

    const handleFormSubmitted = (data) => {
		const ac = axios.CancelToken.source();
		(async() => {
			try{
				setLoader(true);
                const recaptchaToken = await execRecaptcha('developing');
                data.token = recaptchaToken;
				const result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/app-developing`, data,
				{cancelToken: ac.token});
				setLoader(false);
                setReqStatus(result.status);
                setMessage(result.data.message);	
			}catch (err) { console.log(err)}
		})();
		return () => {
			ac.cancel();
		};
	};

    const handleNext = (newData, final = false) => {
        setData((prev) => ({ ...prev, ...newData}));
        
        if(final){
            handleFormSubmitted(newData);
            return null;
        };
        setCurrentStep((prev) => prev + 1);
    };

    const handlePrev = (newData) => {
        setData(prev => ({ ...prev, ...newData}));
        setCurrentStep(prev => prev - 1);
    };

    const steps = [ 
        <StepOne 
			handleNext={handleNext} 
			data={data} 
			setMdlCreate={setMdlCreate}
		/>, 
        <StepOneVitrine
            handleNext={handleNext} 
			handlePrev={handlePrev} 
			data={data}
			setMdlCreate={setMdlCreate}
        />,
        <StepTwo 
			handleNext={handleNext} 
			handlePrev={handlePrev} 
			data={data}
			setMdlCreate={setMdlCreate}
		/>,
        <StepThree
            handleNext={handleNext} 
			handlePrev={handlePrev} 
			data={data}
			setMdlCreate={setMdlCreate}
        />,
         <StepFour
            handleNext={handleNext} 
			handlePrev={handlePrev} 
			data={data}
			setMdlCreate={setMdlCreate}
        />,
        <StepFive
            handleNext={handleNext} 
			handlePrev={handlePrev} 
			data={data}
			setMdlCreate={setMdlCreate}
            loader={loader}
            reqStatus={reqStatus}
        />
    ];

	return(
        <>
            {steps[currentStep]}
            <p>{message}</p>
        </>
	);
};
export default DevelopingCreate;