import './Developing.css';
import Modal from '../../modal/Modal';
import { useState } from 'react';
import DevelopingCreate from './DevelopingCreate';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Developing = () => {
    const [ MdlCreate, setMdlCreate ] = useState(false);
    
    return(
        <div className="developing">
            <HelmetProvider>
                <Helmet>
                    <title>TKDevelop | Agence web Tizi Ouzou Algérie </title>
                    <link rel="canonical" href="https://tkdevelop-dz.com/developing" />
                    <meta name="description" content="TKDevelop |Les étapes de développement des sites et applications web - Tizi Ouzou, Algérie 
                    | Demander un devis : Site Vitrien - Application  web - e-commerce"/>
                </Helmet>
            </HelmetProvider>
             <div className='page'>
                  <p><Link to="/">acceuil</Link></p>
                  <p>{">"}</p>
                  <p>web</p>
            </div>
            <div className="developing-header">
                <h1>les étapes de réalisation d'un site web</h1>
                <p>
                    Avoir un bon site Web est crucial pour votre entreprise, nous pensons et nous codons votre site web de bout en bout.<br/>
                    Nous nous sommes pas les adeptes des générateurs (builders) de sites web qui vous permet de créer un site web en quelques 
                    minutes et par vous-même.<br/>
                    Votre site web mérite d'être bien pensé, et suivre le chemin naturel d'une stratégie de développement de votre activités.<br/>
                    Voici Votre site web en quelques étapes :
                </p>
            </div>
            <div className="developing-steps">
                <h2>Collecte d'informations</h2>
                <p>
                    L’objectif qualité, les informations et fonctions exposées, l'audience ciblée et la charte graphique, 
                    tous ces éléments constituent le point démarrage du projet de réalisation de votre site web. Votre 
                    site web peut être un simple site vitrine ou une application avec des fonctions plus poussées et avancés.
                </p>
                <h2>Planification</h2>
                <p>
                    C'est l'étape de réalisation des brouillons des pages que va contenir votre site web (Frontend ), ainsi
                    que les fonctions exposées au utilisateurs et les interactions avec un serveur (Backend). A la fin de 
                    cette étape nous aurons un plan de votre site à base duquel la conception peut débuter.
                </p>
                <h2>Conception</h2>
                <p>
                    La réalisation des désignes et contenues médiatique, le choix des couleurs et des visuels constitue 
                    une étape non moins importante de votre projet de site web. L’effet d'une image bien pensée peut 
                    alterner à de longues présentations textuelles.
                </p>
                <h2>Contenu</h2>
                <p>
                    Le contenu de votre site Web est l'aspect le plus important de votre projet. Pour passer le message et
                    encourager les utilisateurs à continuer consulter votre site web, votre contenu doit être intéressant, 
                    pertinent et fiable. Un contenu bien rédigé monte votre site dans les résultats de recherche de google.
                </p>
                <h2>Intégration et déploiement</h2>
                <p>
                    Avec le contenu, les conceptions et le plan de votre site web entre les mains, nous passons à son 
                    intégration. Pour que vous puissiez valider et tester les fonctionnements et mises à jour, nous
                    utilisons la méthode d'intégration et déploiement continue, nous développons en local votre site web et 
                    vous verrez en ligne son évolution.
                </p>
            </div>
            <button className="ask-qote-btn" onClick={() => setMdlCreate(true)}>
                <svg className="ask-qote-icon"> 
                    <use xlinkHref="/img/icons/sprite.svg#icon-request_quote"></use>
                </svg>
                <span>Devis ?</span>
            </button>
            <Modal open={MdlCreate}>
                <div className='modal-close'>
                    <button onClick={() => setMdlCreate(false)}>
                        <svg className="modal-close-icon"> 
                            <use xlinkHref="/img/icons/sprite.svg#icon-circle-with-cross"></use>
                        </svg> 
                    </button>
                </div>
                <div className='modal-content'>
                    <DevelopingCreate setMdlCreate={setMdlCreate}/>
               </div>
            </Modal>
        </div>
    );
};

export default Developing;