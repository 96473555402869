import './Footer.css';

const Footer = () => {
    return(
        <div className="footer">
             <div className="footer-stamp">
                <img src="/img/patte.webp" alt="tkdevelop"/>
                <p>Par: TAKILT Asmane</p>
            </div>
            <div className="footer-legal"> &copy; {new Date().getFullYear()} All rights reserved</div>
        </div>
    );
};

export default Footer;