import { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../form/FormikControl';
import '../../form/form.css';
import useRecaptchV3 from '../../hooks/recaptcha';

const HostingCreate = ({setMdlCreate, pack}) => {

	const execRecaptcha = useRecaptchV3(`${process.env.REACT_APP_RECAPTCHA_KEY}`);

	const validationSchema = Yup.object().shape({
		name : Yup.string().required('Entrer une valeur!').max(100,'100 caracters max!'),
		email : Yup.string().required('Entrer une valeur!').email('entrer un email valide').max(50,'50 caracters max!'),
		phone : Yup.string().required('Entrer une valeur!').max(25,'25 caracters max!'),
		description : Yup.string().max(400,'400 caracters max!')
	});

	const [ loader, setLoader ] = useState(false);
	const [ message, setMessage ] = useState("");
	const [ reqStatus, setReqStatus ] = useState(0);

	
	const initialValues = {
		pack : pack,
		name : "",
		email: "",
		phone: "",
		description: "",
		administration: false,
		cicd: false,
		autobackup : false,
	};

	const onSubmit = (values) => {
		const ac = axios.CancelToken.source();
		(async() => {
			try{
				setLoader(true);
				const recaptchaToken = await execRecaptcha('hosting');
				let _administration = values.administration ? 3500 : 0;
				let _cicd = values.cicd ? 1500 : 0
				let _autobackup = values.autobackup ? 1000 : 0
				values.options = { 
					administration : _administration,
					cicd : _cicd,
					autobackup: _autobackup
				};
				values.total = Number(pack.title.split("-")[1].split(" ")[1] +""+pack.title.split("-")[1].split(" ")[2]) 
				 + _administration + _cicd + _autobackup;
				values.token = recaptchaToken;
				const result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/app-hosting`, values,
				{cancelToken: ac.token});
				setLoader(false);
				setMessage(result.data.message);
				setReqStatus(result.status)	
			}catch (err) { console.log(err)}
		})();
		return () => {
			ac.cancel();
		};
	};

	return(
		<Formik 	
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}>
			{ formik => {
				const { values } = formik;
				let admin = values.administration ? 3500 : 0;
				let cicd = values.cicd ? 1500 : 0
				let backup = values.autobackup ? 1000 : 0
				return (
					<>
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								Commande de : <span>{`VPS ${pack.title}`}</span>
							</div>
							<div className="form-content">
								<div className='form-groups'>
									<FormikControl control="input" name="name" label="nom & prénom / raison sociale"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="input" name="email" label="email"/>
									<FormikControl control="input" name="phone" label="téléphone"/>
								</div>
								<div className='form-groups'>
									<FormikControl control="textarea" name="description" label="Vos notes"/>
								</div>
								<h4>Options:</h4>
								<div className='checkbox-groups'>
									<FormikControl control="switches" name="administration" label="Administration (5 000.00 DA/Mois)"/>
								</div>	
								<div className='checkbox-groups'>
									<FormikControl control="switches" name="cicd" label="CI/CD (2 500.00 DA/Mois)"/>
								</div>
								<div className='checkbox-groups'>
									<FormikControl control="switches" name="autobackup" label="Sauvegarde auto (1 000.00 DA/Mois)"/>
								</div>	
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => setMdlCreate(false)} className="form-btn form-btn-thirdary">
									cancel
								</button>
								<button className="form-btn form-btn-secondary" type="submit"
									disabled={reqStatus === 200 ? true: false }  
									style={reqStatus === 200 ? {cursor : "not-allowed"} : null}
								>
									{ loader ? <>Envoi...</> : <>Commande</>}								
								</button>
								<h4>Total :
									{Number(pack.title.split("-")[1].split(" ")[1] +""+pack.title.split("-")[1].split(" ")[2])
									+ admin + cicd + backup + " DA/Mois"}
								</h4>
							</div>
						</Form>
					</div>
					<p>{message}</p>
					</>
				)}
			}
		</Formik>
	);
};
export default HostingCreate;