import { Switch, Route, Redirect } from 'react-router-dom';
import './styles.css';
import Nav from './components/nav/Nav';
import Sidebar from './components/sidebar/Sidebar';
import Backdrop from './components/sidebar/Backdrop';
import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import Hosting from './components/pages/hosting/Hosting';
import Footer from './components/footer/Footer';
import Erp from './components/pages/erp/Erp';
import ErpPlayVideo from './components/pages/erp/ErpPlayVideo';
import Developing from './components/pages/developing/Developing';
import Test from './components/Test';
import Marketing from './components/pages/marketing/Marketing';

const App  = () => {
    let routes = (
      <div className="container" style={{
            backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-1.webp"})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
            }}>
        <Nav/>
        <Sidebar/>
        <Backdrop/>
        <div className="content">
          <Switch>
            <Route exact path="/"><Home/></Route>
            <Route exact path="/about"><About/></Route>
            <Route exact path="/hosting"><Hosting/></Route>
            <Route exact path="/developing"><Developing/></Route>
            <Route exact path="/matyn-erp"><Erp/></Route>
            <Route exact path="/matyn-erp-video-player/:video_id"><ErpPlayVideo/></Route>
            <Route exact path="/marketing"><Marketing/></Route>
            <Route exact path="/test"><Test/></Route>
            <Redirect exact to="/"/>            
          </Switch>
        </div>
        <Footer/>
      </div>
    );
  return (
    <>
      {routes}
    </>
  );
};
export default App;
