import { Link } from 'react-router-dom';
import './Home.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Home = () => {

 	return (
        <div className='header'>
            <HelmetProvider>
              <Helmet>
                <title>TKDevelop | Agence web Tizi Ouzou Algérie </title>
                <link rel="canonical" href="https://tkdevelop-dz.com" />
                <meta name="description" content="TKDevelop | Agence web - Tizi Ouzou, Algérie 
                | Développement de sites et applications web : Site Vitrien - Application  web - e-commerce
                | hébergement de site web : Mutualisé - vps - administration systémes - CI/CD
                | ERP Matyn : Achats - Stocks - CRM - Ventes - Finances et trésorerie - Production - Logistique - Ressources humaine - Comptabilité
                | Marketing Digital : Emailing - Réseaux Sociaux - Infographie"/>
            </Helmet>
            </HelmetProvider>
            <div className='page'>
                  <p>acceuil</p>
                  <p>{">"}</p>
            </div>
            <header className='header-typo'>
                  <img src='/img/logo/alpha/logo_alpha_md.webp' alt="tkdevelop"/>
                  <h1>tkdevelop</h1>
                  <h2> 
                     TKDevelop, Agence d'infogérance  en charge de votre parc informatique : <br/>
                     Sites et applications web - 
                     Infrastructures (serveurs) - 
                     Déploiement ERP (Matyn) - <br/>
                     Marketing digital(réseaux sociaux, emailing, infographie)
                  </h2>
            </header> 
            <div className='feature'>
               <div className='feature-title'>ce que nous faisons!</div>
               <section className='feature-cards'>
                     <div className='feature-card'>
                        <span className="feature-card-title">
                           <svg className="feature-card-title-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-network"></use>
                           </svg>
                           <h2>Sites et applications web</h2>
                        </span>
                        <p>
                           Nous codons votre site web de bout en bout, nous sommes capable de créer ou renforcer votre existence web, nous vous 
                           accompagnons dans les stratègies digitales de votre entreprise.
                        </p>
                        <span className='feature-card-link'>
                           <Link to="/developing">Demander un devis</Link>
                           <svg className="feature-card-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                           </svg>
                        </span>
                     </div>
                     <div className='feature-card'>
                        <span className="feature-card-title">
                           <svg className="feature-card-title-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-storage"></use>
                           </svg>
                           <h2>Hébergement et VPS</h2>
                        </span>
                        <p>
                        Heberger toutes vos applications, sites web et services graces à nos vps bon marché. Nous assurons l'installation et 
                        l'administration  de vos serveurs.<br/>
                        Installer vos sites web, applications, bases de données de votre choix...
                        </p>
                        <span className='feature-card-link'>
                           <Link to="/hosting">Voir nos offres ici</Link>
                           <svg className="feature-card-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                           </svg>
                        </span>
                     </div>
                     <div className='feature-card'>
                        <span className="feature-card-title">
                           <svg className="feature-card-title-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-settings_applications"></use>
                           </svg>
                           <h2>Matyn Erp</h2>
                        </span>
                        <p>
                        L'ERP Matyn est un progiciel de gestion intégré qui vous permet de piloter l'ensemble des services de votre entreprise, 
                        Achats - Stocks - Ventes et CRM - Finances - RH - Logistique - Comptabilité ...
                        </p>
                        <span className='feature-card-link'>
                           <Link to="/matyn-erp" target="_blank">Voir les détails ici</Link>
                           <svg className="feature-card-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                           </svg>
                        </span>
                     </div>
                     <div className='feature-card'>
                        <span className="feature-card-title">
                           <svg className="feature-card-title-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-megaphone"></use>
                           </svg>
                           <h2>Marketing digital</h2>
                        </span>
                        <p>
                        Entrer en communication avec vos prospects grâces aux compagnes d'emailing, gerer vos communautés sur les réseaux sociaux,
                        Intégré des vidéos dans vos contenus web, référencement naturel SEO.
                        </p>
                        <span className='feature-card-link'>
                           <Link to="/" target="_blank">Voir les détails ici</Link>
                           <svg className="feature-card-icon"> 
                              <use xlinkHref="/img/icons/sprite.svg#icon-arrow-long-right"></use>
                           </svg>
                        </span>
                     </div>
               </section>
            </div>
        </div>
 	);
};

export default Home; 

