import Input from './controls/Input';
import Textarea from './controls/Textarea';
import Switches from './controls/Switches';
import Radio from './controls/Radio';
import Select from './controls/Select';


const FormikControl = (props) => {
	const {control, ...rest } = props;
	switch(control){
		case 'input' : return <Input {...rest}/>
		case 'textarea' : return <Textarea {...rest}/>
		case 'switches' : return <Switches { ...rest}/>
		case 'radio' : return <Radio {...rest}/>
		case 'select' : return <Select {...rest}/>
		default : return null
	}
};

export default FormikControl;