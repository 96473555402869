import { Field} from 'formik';


const Switches = props => {
 	const { label, name, round, ...rest} = props;
 	return(
 		<div className="checkbox-group checkbox-group-switches">
            <label className="switch">
                <Field name={name} type="checkbox" {...rest}/>
                <span className={"slider " + round}></span>
            </label>   
            <span className="label-switches">{label}</span>
		</div>
 	);
};

export default Switches;

							