import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../form/FormikControl';

const StepOneVitrine = (props) => {
    
    const validationSchema = Yup.object().shape({
		
	});

    const handleSubmit = (values) => {
       props.handleNext(values)
    };
    
    return(
        <Formik 	
			initialValues={props.data}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}>
			{ ({values}) => {
				return (
					<div className="form"  style={{backgroundImage:`url(${process.env.PUBLIC_URL + "/img/landing-2.png"})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
						<Form >
							<div className='form-title'>
								<span>Demande de devis site web : {values.project_type}</span>
							</div>
							<div className="form-content">
								{values.project_type === "vitrine"?
								<>
									<h2>Les pages: </h2>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.page_acceuil"  label="acceuil"/>
                                        <FormikControl control="switches" name="site_vitrine.page_apropos"  label="à propos"/>
									</div>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.page_contact"  label="contact"/>
                                        <FormikControl control="switches" name="site_vitrine.page_produit"  label="produits / services"/>
									</div>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.temoignage"  label="temoignages clients"/>
									</div>
									<h2>Formulaires:</h2>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.form_contact"  label="formulaire contact"/>
										<FormikControl control="switches" name="site_vitrine.form_devis"  label="formulaire devis"/>									</div>
									<div className='form-groups'>
									</div>
									<h2>Fonctionnalités avancées : </h2>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.rdv"  label="Prise de RDV"/>
                                        <FormikControl control="switches" name="site_vitrine.blog"  label="Système blog"/>
									</div>
									<div className='form-groups'>
										<FormikControl control="switches" name="site_vitrine.page_carriere"  label="offres d'emploi"/>
										<FormikControl control="switches" name="site_vitrine.admin"  label="espaces admin"/>
									</div>
								</>
								: 
								<div className='form-groups'>
									<FormikControl control="textarea" name="site_advanced" rows="15"  label="Déscription de vos besoins exact?"/>
								</div>
							}
							</div>
							<div className="form-actions">
								<button type="button" onClick={() => props.setMdlCreate(false)} className="form-btn form-btn-thirdary">
									annuler
								</button>
								<button className="form-btn form-btn-secondary" type="submit">
									Suivant								
								</button>
								<button className="form-btn form-btn-secondary" type="button" onClick={() => props.handlePrev(values)}>
                                    précédent
                                </button>
							</div>
						</Form>
					</div>
				)}
			}
		</Formik>
    );
};

export default StepOneVitrine;