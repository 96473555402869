import { useCallback, useEffect, useState } from "react";

const useRecaptchV3 = (sitekey) => {
    const [ isRecaptchReady, setIsRecaptchReady ] = useState(false);
    useEffect(() => {
        if(window.grecaptcha){
            setIsRecaptchReady(true);
        } else {
            const script = document.createElement('script');
            script.src = `https://google.com/recaptcha/api.js?render=${sitekey}`;
            script.async = true;
            document.head.appendChild(script);
            script.onload = () => {setIsRecaptchReady(true)};
        }
    }, [sitekey]);

    const executeRecaptcha = useCallback(async(action) => {
            if(isRecaptchReady && window.grecaptcha){
                return await window.grecaptcha.execute(sitekey, {action});
            };
    }, [sitekey, isRecaptchReady]);
    return executeRecaptcha;
};

export default useRecaptchV3;